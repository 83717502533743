import React, { PropsWithChildren, ReactElement } from "react";
import useIsBrowser from "../../../hooks/useIsBrowser";
import styles from "./HeroStatic.module.scss";

interface HeroScheduleProps {
    img: any,
    alt: string,
    title?:string
}

const Hero = (props: PropsWithChildren<HeroScheduleProps>): ReactElement => {
    const { img, alt, title } = props
    const { isBrowser, key } = useIsBrowser();

    if (!isBrowser) return null;

    return (
        <div
            className={`${styles.heroMedia}`}
            key={key}>
                <img src={img} alt={alt} className={styles.img}/>
            {title && <h1>{title}</h1>}
        </div>
    );
};

export default Hero;

import React, { PropsWithChildren, ReactElement } from "react";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import { graphql } from "gatsby"
import styles from "./GymMembers.module.scss"
import Helmet from "../../components/Helmet";
import Hero from "../../components/shared/HeroStaticPages";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";


interface ITermTexts {
    type: string, text: string, spans: any[]
}
const GymMembers = (props: PropsWithChildren<any>): ReactElement => {
    const data = JSON.parse(props.data.allPrismicGymMembershipPolicyPage.edges[0].node.dataString);

    const isDesktop = useWindowWidth(heroDesktopMinWidth);
    const background = isDesktop ? data.hero_background?.url : data.mobile_background?.url || data.hero_background?.url;

    const heroAltText = (): string => 
    {
        let altText = "";
        if (isDesktop && data.hero_alt_text?.length > 0) {
            altText = data.hero_alt_text[0].text;
        } else if (!isDesktop && data.hero_alt_text_mobile?.length > 0) {
            altText = data.hero_alt_text_mobile[0].text;
        }
        return altText;
    }

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Gym and Members")}
                description={getSeoDescription(data, "Gym and Members policy page")}
                keywords={getSeoKeywords(data, "")} 
            />
            
            {background && <Hero img={background} alt={heroAltText()}/>}
            <main className={`mainBox ${styles.mainWrapper}`}>
                <section className={styles.mainInformation}>
                    {data.terms_title && <h2 className={styles.mainTitle}>{data.terms_title[0].text}</h2>}
                    {data.terms_introduction &&
                        data.terms_introduction.map((introduction:ITermTexts, i :number) => (
                            <p className={styles.mainDescription} key={i}>{introduction.text}</p>
                        ))}
                </section>
                <section>
                    {data.terms_section && data.terms_section.map((information: { term_title: Array<ITermTexts>, term_information: Array<ITermTexts> }, i: number) => {
                        return <div key={i}>
                            <h3 className={styles.termSectionTitle}>{information.term_title[0]?.text}</h3>

                            {information.term_information.map((term: ITermTexts, i:number) => {
                                let termText = term?.text || '';
                                let links = term?.spans?.filter(x => x.type === "hyperlink" && x.data.link_type === "Web") || [];

                                for(const link of links){
                                    let linkText = termText.slice(link.start, link.end);
                                    let linkTag = `<a target="_blank" href="${link.data.url}">${linkText}</a>`;
                                    
                                    termText = `${termText.substring(0, link.start)}${linkTag}${termText.substring(link.end, termText.length)}`;
                                }

                                // return <p className={styles.termSectionDescription} key={i} >{termText}</p>
                                return <p className={styles.termSectionDescription} key={i} dangerouslySetInnerHTML={{ __html: termText }}></p>
                            })}

                        </div>
                    })}
                </section>

            </main>
        </>
    );
};

export const query = graphql`
    query {
        allPrismicGymMembershipPolicyPage {
            edges {
                node {
                    dataString
                }
            }
        }
    }
`;
export default GymMembers;
